import { css, styled } from '@mui/material';

export const overflowEllipsis = css({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

export const lowerCapitalize = css({
  textTransform: 'lowercase',
  '&::firstLetter': {
    textTransform: 'uppercase',
  },
});

export const FullSizeContainerStyled = styled('div')({
  margin: '0 auto',
  height: '100%',
});

export const ContentWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  margin: '0 auto',
  padding: `0 calc(${theme.spacings.unit(
    36 / 4
  )} + env(safe-area-inset-right)) 0 calc(${theme.spacings.unit(
    36 / 4
  )} + env(safe-area-inset-left))`,
  [theme.breakpointsMediaQuery.up('md')]: {
    padding: `0 ${theme.spacings.unit(26)}`,
  },
  [theme.breakpointsMediaQuery.up('xl')]: {
    maxWidth: theme.spacings.unit(1960 / 4),
    paddingLeft: theme.spacings.unit(20 / 4),
    paddingRight: theme.spacings.unit(20 / 4),
  },
}));

export const SingleContentWrapperMobile = styled('div')(({ theme }) => ({
  [theme.breakpointsMediaQuery.down('md')]: {
    padding: `0 calc(${theme.spacings.unit(
      36 / 4
    )} + env(safe-area-inset-right)) 0 calc(${theme.spacings.unit(
      36 / 4
    )} + env(safe-area-inset-left))`,
  },
}));

export const MobilePaddingLessContentWrapper = styled(ContentWrapper)(() => ({
  padding: '0 env(safe-area-inset-right) 0 env(safe-area-inset-left)',
}));

export const SecondaryBg = styled('div')(({ theme }) => ({
  background: theme.colors.white,
  paddingTop: theme.spacings.unit(48 / 4),
}));

export const Ios = styled('div')(({ theme }) => ({
  display: 'flex',
  zIndex: 999,
  width: '100%',
  position: 'fixed',
  top: 0,
  background: theme.colors.background,
}));

export const FlexContainerStyled = styled('div')({
  display: 'flex',
});

export const FlexRowContainerStyled = styled(FlexContainerStyled)({
  flexDirection: 'row',
  height: '100%',
});

export const FlexContainerCenteredStyled = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const HideOnDesktop = styled('span')(({ theme, breakpoint }) => ({
  [theme.breakpointsMediaQuery.up(breakpoint)]: {
    display: 'none',
  },
  [theme.breakpointsMediaQuery.up('md')]: {
    display: 'none',
  },
}));

export const HideOnMobile = styled('span')(({ theme, breakpoint }) => ({
  [theme.breakpointsMediaQuery.down(breakpoint)]: {
    display: 'none',
  },
  [theme.breakpointsMediaQuery.down('md')]: {
    display: 'none',
  },
}));

export const StyledLink = styled('a')(({ theme }) => ({
  display: 'inline-block',
  color: `${theme.colors.primaryText}`,
  cursor: 'pointer',
  textDecoration: 'underline',
  fontSize: theme.spacings.unit(3),
}));

export const ExternalLinkButton = styled('a')(({ theme }) => ({
  color: `${theme.colors.secondaryText}`,
  '& svg:hover': {
    '& path': {
      fill: `${theme.colors.primary}`,
    },
  },
}));

export const SectionTitle = styled('h2')(({ theme }) => ({
  marginBottom: theme.spacings.unit(8),
  fontSize: theme.fonts.utility.size(16 / 2),
}));

export const ImageWrapper = styled(ContentWrapper)(({ theme }) => ({
  padding: `${theme.spacings.unit(11)} ${theme.spacings.unit(9)}`,
  display: 'flex',
  justifyContent: 'center',
  [theme.breakpointsMediaQuery.up('sm')]: {
    maxWidth: '50%',
  },
  [theme.breakpointsMediaQuery.up('md')]: {
    padding: `${theme.spacings.unit(11)} ${theme.spacings.unit(9)}`,
    maxWidth: '40%',
  },
}));

export const ImageWrapperDownload = styled(ContentWrapper)(({ theme }) => ({
  margin: `${theme.spacings.unit(11)} auto`,
  display: 'flex',
  justifyContent: 'center',
  [theme.breakpointsMediaQuery.up('sm')]: {
    maxWidth: '50%',
  },
  [theme.breakpointsMediaQuery.up('md')]: {
    padding: `${theme.spacings.unit(11)} ${theme.spacings.unit(9)}`,
    maxWidth: '40%',
  },
}));
