import React from 'react';
import { TextSize } from '../../models/enum';
import { Buttonstyled, ButtonAStyled } from './Button.style';

export interface IProps {
  text?: string | Element | any;
  startIcon?: JSX.Element;
  endIcon?: JSX.Element;
  styleBtn?: ButtonStyle;
  uppercase?: boolean;
  fullWidth?: boolean;
  onClick?: (event: any) => void;
  title?: string;
  className?: string;
  type?: string | Element | any;
  textSize?: TextSize;
  href?: () => string | Promise<string>;
  asA?: boolean | undefined;
}

export enum ButtonStyle {
  'primary',
  'secondary',
  'tertiary',
  'outLined',
  'disabled',
  'contactFormPage',
  'reservedArea',
}

const Button: React.FC<IProps> = React.forwardRef(
  (
    {
      text,
      startIcon,
      endIcon,
      styleBtn,
      uppercase,
      fullWidth,
      onClick,
      title,
      className,
      type,
      textSize,
      href,
      asA,
    },
    ref
  ) => {
    const onDownload = async (): Promise<void> => {
      let hrefLink: string;
      if (typeof href !== 'string' && href !== undefined) {
        hrefLink = (await href()) || '';
      } else {
        hrefLink = href || '';
      }
      const link = document.createElement('a');
      link.download = hrefLink || '';
      link.href = hrefLink || '';
      link.click();
    };

    return !asA ? (
      <Buttonstyled
        ref={ref}
        onClick={href ? onDownload : onClick}
        startIcon={startIcon}
        endIcon={endIcon}
        styleBtn={styleBtn}
        uppercase={uppercase}
        fullWidth={fullWidth}
        title={title}
        className={className}
        children={text}
        type={type}
        disableRipple={styleBtn === ButtonStyle.disabled}
        textSize={textSize}
      />
    ) : (
      <ButtonAStyled
        ref={ref}
        startIcon={startIcon}
        endIcon={endIcon}
        styleBtn={styleBtn}
        uppercase={uppercase}
        fullWidth={fullWidth}
        title={title}
        className={className}
        children={text}
        type={type}
        disableRipple={styleBtn === ButtonStyle.disabled}
        textSize={textSize}
        href={href}
      />
    );
  }
);

export default Button;
