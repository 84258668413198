import React, { useEffect, useState } from 'react';
import Image, { ImageProps } from 'next/image';

interface ImageWithFallbackProps extends ImageProps {
  fallbackSrc: string;
  isPrerenderRequest: boolean;
}

const ImageWithFallback = (props: ImageWithFallbackProps) => {
  const { src, fallbackSrc, isPrerenderRequest, ...rest } = props;
  const [imgSrc, setImgSrc] = useState(src);

  useEffect(() => {
    setImgSrc(src);
  }, [src]);

  return (
    <Image
      {...rest}
      priority={isPrerenderRequest || rest.priority}
      placeholder={isPrerenderRequest ? 'empty' : rest.placeholder}
      src={imgSrc}
      onError={() => {
        // on normal page navigation
        setImgSrc(fallbackSrc);
      }}
    />
  );
};

export default ImageWithFallback;
