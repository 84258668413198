import { styled, Button, ButtonProps } from '@mui/material';
import { TextSize } from '../../models/enum';
import { ButtonStyle } from './Button';

export const Buttonstyled = styled(Button, {
  shouldForwardProp: (prop) =>
    prop !== 'styleBtn' &&
    prop !== 'textSize' &&
    prop !== 'uppercase' &&
    prop !== 'fullWidth' &&
    prop !== 'ref' &&
    prop !== 'startIcon' &&
    prop !== 'endIcon',
})<ButtonProps>(({ theme, styleBtn, disabled, uppercase, textSize }) => {
  styleBtn = disabled ? 'disabled' : styleBtn;

  const disabledStyle = {
    color: theme.colors.disabled,
    background: theme.colors.disabled,
    '&&&:hover, &:active': {
      color: theme.colors.disabled,
      background: theme.colors.disabled,
      cursor: 'not-allowed',
    },
    '& path': {
      fill: theme.colors.disabled,
    },
  };

  const primaryStyle = {
    color: theme.colors.white,
    background: theme.colors.primary,
    padding: `${theme.spacings.unit(1)} ${theme.spacings.unit(4)}`,
    '&&&:hover': {
      backgroundColor: theme.colors.primaryHover,
    },
    '& path': {
      fill: theme.colors.white,
    },
  };

  const secondaryStyle = {
    color: theme.colors.primary,
    background: theme.colors.background,
    border: `1px solid ${theme.colors.primary}`,
    letterSpacing: theme.fonts.secondaryButtonSpacing,
    padding: `${theme.spacings.unit(1)} ${theme.spacings.unit(4)}`,
    '&&&:hover': {
      textDecoration: 'underline',
      backgroundColor: theme.colors.secondaryBackgroundColorHover,
    },
    '& path': {
      fill: theme.colors.primary,
    },
  };

  const outlinedStyle = {
    '&.MuiButtonBase-root': {
      color: theme.colors.white,
      border: '1px solid white',
      borderRadius: 0,
      padding: `
      ${theme.spacings.unit(1)} 
      ${theme.spacings.unit(4)} 
      ${theme.spacings.unit(1)} 
      ${theme.spacings.unit(4)}`,
      '&&&:hover': {
        borderRadius: 0,
        border: `1px solid ${theme.colors.primary}`,
        color: theme.colors.primary,
      },
      '& .MuiButton-root:focus': {
        borderRadius: 0,
      },
      '& .MuiButton-root:active': {
        borderRadius: 0,
      },
    },
  };

  const tertiaryStyle = {
    color: theme.colors.primary,
    background: theme.colors.tertiaryBackgroundColor,
    border: `1px solid ${theme.colors.primary}`,
    '&&&:hover': {
      textDecoration: 'underline',
      backgroundColor: theme.colors.tertiaryBackgroundColorHover,
    },
    '& path': {
      fill: theme.colors.primary,
    },
  };

  const contactFormPage = {
    color: theme.colors.primary,
    background: theme.colors.background,
    border: `1px solid ${theme.colors.primary}`,
    minWidth: '300px',
    '&&&:hover': {
      background: theme.colors.primary,
      color: theme.colors.white,
    },
  };

  const smallStyle = { fontSize: '0.8rem' };
  const mediumStyle = { fontSize: '1rem' };
  const largeStyle = { fontSize: '1.2rem' };

  const typeStyle = (): any => {
    switch (styleBtn) {
      case ButtonStyle.primary:
      default:
        return primaryStyle;
      case ButtonStyle.secondary:
        return secondaryStyle;
      case ButtonStyle.tertiary:
        return tertiaryStyle;
      case ButtonStyle.outLined:
        return outlinedStyle;
      case ButtonStyle.disabled:
        return disabledStyle;
      case ButtonStyle.contactFormPage:
        return contactFormPage;
    }
  };

  const sizeStyle = () => {
    switch (textSize) {
      case TextSize.small:
        return smallStyle;
      case TextSize.medium:
      default:
        return mediumStyle;
      case TextSize.large:
        return largeStyle;
    }
  };

  const common = {
    border: '1px solid transparent',
    textTransform: uppercase ? 'uppercase' : 'none',
    borderRadius: theme.borders.roundness,
    paddingLeft: 0,
    padding: `
      ${theme.spacings.unit(15 / 4)} 
      ${theme.spacings.unit(20 / 4)} 
      ${theme.spacings.unit(15 / 4)} 
      ${theme.spacings.unit(20 / 4)}`,
    marginBottom: 0,

    '&&&:hover': {
      borderRadius: theme.borders.roundness,
    },

    '& .MuiButton-root:focus': {
      borderRadius: theme.borders.roundness,
    },

    '& .MuiButton-root:active': {
      borderRadius: theme.borders.roundness,
    },

    '& svg': {
      top: theme.spacings.unit(1),
      right: theme.spacings.unit(1),
    },
  };

  return {
    ...common,
    ...typeStyle(),
    ...sizeStyle(),
  };
});

export const ButtonAStyled = styled('a', {
  shouldForwardProp: (prop) =>
    prop !== 'styleBtn' &&
    prop !== 'textSize' &&
    prop !== 'uppercase' &&
    prop !== 'fullWidth' &&
    prop !== 'ref' &&
    prop !== 'startIcon' &&
    prop !== 'endIcon',
})<ButtonProps>(({ theme, styleBtn, disabled, uppercase, textSize }) => {
  styleBtn = disabled ? 'disabled' : styleBtn;

  const disabledStyle = {
    color: theme.colors.disabled,
    background: theme.colors.disabled,
    '&&&:hover, &:active': {
      color: theme.colors.disabled,
      background: theme.colors.disabled,
      cursor: 'not-allowed',
    },
    '& path': {
      fill: theme.colors.disabled,
    },
  };

  const primaryStyle = {
    color: theme.colors.white,
    background: theme.colors.primary,
    padding: `${theme.spacings.unit(1)} ${theme.spacings.unit(4)}`,
    '&&&:hover': {
      backgroundColor: theme.colors.primaryHover,
    },
    '& path': {
      fill: theme.colors.white,
    },
  };

  const secondaryStyle = {
    color: theme.colors.primary,
    background: theme.colors.background,
    textDecoration: 'underline',
    padding: `${theme.spacings.unit(1)} ${theme.spacings.unit(4)}`,
    '&&&:hover': {
      textDecoration: 'underline',
      backgroundColor: theme.colors.secondaryBackgroundColorHover,
    },
    '& path': {
      fill: theme.colors.primary,
    },
  };

  const outlinedStyle = {
    '&.MuiButtonBase-root': {
      color: theme.colors.white,
      border: '1px solid white',
      borderRadius: 0,
      padding: `
      ${theme.spacings.unit(1)} 
      ${theme.spacings.unit(4)} 
      ${theme.spacings.unit(1)} 
      ${theme.spacings.unit(4)}`,
      '&&&:hover': {
        borderRadius: 0,
        border: `1px solid ${theme.colors.primary}`,
        color: theme.colors.primary,
      },
      '& .MuiButton-root:focus': {
        borderRadius: 0,
      },
      '& .MuiButton-root:active': {
        borderRadius: 0,
      },
    },
  };

  const tertiaryStyle = {
    color: theme.colors.primary,
    background: theme.colors.tertiaryBackgroundColor,
    border: `1px solid ${theme.colors.primary}`,
    '&&&:hover': {
      textDecoration: 'underline',
      backgroundColor: theme.colors.tertiaryBackgroundColorHover,
    },
    '& path': {
      fill: theme.colors.primary,
    },
  };

  const contactFormPage = {
    color: theme.colors.primary,
    background: theme.colors.background,
    border: `1px solid ${theme.colors.primary}`,
    minWidth: '300px',
    '&&&:hover': {
      background: theme.colors.primary,
      color: theme.colors.white,
    },
  };

  const smallStyle = { fontSize: '0.8rem' };
  const mediumStyle = { fontSize: '1rem' };
  const largeStyle = { fontSize: '1.2rem' };

  const typeStyle = (): any => {
    switch (styleBtn) {
      case ButtonStyle.primary:
      default:
        return primaryStyle;
      case ButtonStyle.secondary:
        return secondaryStyle;
      case ButtonStyle.tertiary:
        return tertiaryStyle;
      case ButtonStyle.outLined:
        return outlinedStyle;
      case ButtonStyle.disabled:
        return disabledStyle;
      case ButtonStyle.contactFormPage:
        return contactFormPage;
    }
  };

  const sizeStyle = () => {
    switch (textSize) {
      case TextSize.small:
        return smallStyle;
      case TextSize.medium:
      default:
        return mediumStyle;
      case TextSize.large:
        return largeStyle;
    }
  };

  const common = {
    cursor: 'pointer',
    border: '1px solid transparent',
    textTransform: uppercase ? 'uppercase' : 'none',
    borderRadius: theme.borders.roundness,
    paddingLeft: 0,
    padding: `
      ${theme.spacings.unit(18 / 4)} 
      ${theme.spacings.unit(24 / 4)} `,
    marginBottom: 0,
    transition:
      'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    textDecoration: 'none',

    '& svg': {
      top: theme.spacings.unit(1),
      right: theme.spacings.unit(1),
    },
  };

  return {
    ...common,
    ...typeStyle(),
    ...sizeStyle(),
  };
});
